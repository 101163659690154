import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class DashboardService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;

    }

    getStats() {
        let url = `${this.#api}/stats`;
        return apiService.get(url);
    }
    getTimelineStats() {
        let url = `${this.#api}/timeline/stats`;
        return apiService.get(url);
    }
    getExamPerformanceStats(data={}){
        let url = `${this.#api}/exam-performance/stats`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getExamPerformanceStatsInTableView(data={}){
        let url = `${this.#api}/exam-performance/stats/table-view`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}