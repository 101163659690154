import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class CalenderService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/student`;
      }

      show() {
            let url = `${this.#api}/calendar`
            return apiService.get(url);
      }
      attendance(data) {
            let url = `${this.#api}/attendance/report`
            return apiService.post(url,data);
      }
      attendanceSummary(data) {
            let url = `${this.#api}/attendance/summary`
            return apiService.post(url,data);
      }




}
