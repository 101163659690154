import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class CoursesService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/courses`;

    }

    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    paginate(data,index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index)
            url = url + '?page=' + index;
        return apiService.get(url);
    }

    getByProgram(programId) {
        let url = `${this.#api}/${programId}/program`
        return apiService.get(url);
    }

    getOptionalCourseByProgramAndGrade(programId,gradeId) {
        let url = `${this.#api}/${programId}/grade-optional/${gradeId}/courses`
        return apiService.get(url);
    }

    // update(id, data) {
    //     let url = `${this.#api}/${id}`
    //     return apiService.put(url, data);
    //
    // }
    //
    // store(data) {
    //     let url = `${this.#api}`
    //     return apiService.post(url, data);
    //
    // }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    // delete(id) {
    //     let url = `${this.#api}/${id}`
    //     return apiService.delete(url);
    // }
}
