import Apiservice from '../../api.service';
import { API_URL } from '../../../../common/config.js'
const apiService = Apiservice;


export default class ProgramService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/student/program`;
      }

      paginate(data=null, index=1) {
            let url = `${this.#api}`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index != null && !queryString)
                  url = url + '?' + "page=" + (index);
            else
                  url = url + '&' + "page=" + (index);
            return apiService.get(url);
      }

      getByLevelId(levelId) {
            let url = `${this.#api}-level/${levelId}`;
            return apiService.get(url);
      }

      getAllByLevelId(levelId) {
            let url = `${this.#api}/${levelId}/all`;
            return apiService.get(url);
      }


      all() {
            let url = `${this.#api}s/all`;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}/update`
            return apiService.post(url,data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }






}
