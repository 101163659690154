import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class EducationService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    educationLevels() {
        let url = `${this.#api}/education-levels`;
        return apiService.get(url);
    }

    documentTypes() {
        let url = `${this.#api}/document-types`;
        return apiService.get(url);
    }

    getRoles(roles) {
        let accessRoles = [];
        let userRoles = roles.split(",");
        for (let role of userRoles) {
            let temp = this.getAccessRolesByName(role)
            accessRoles = accessRoles.concat(temp);
        }
        let finalAccessRoles = [];
        accessRoles.map(ele => {
            if (!finalAccessRoles.includes(ele)) {
                finalAccessRoles.push(ele)
            }
        })
        return finalAccessRoles;
    }

    getAccessRolesByName(role) {
        let roles = [];
        role = role.trim();
        switch (role) {
            case "student":
                roles = ["user", "student"];
                break;
            case "cms-manager":
                roles = ["cms-manager"];
                break;
            case "teacher":
                roles = ["user", "teacher","only_teacher"];
                break;
            case "parent":
                roles = ["user", "parent"];
                break;
            case "coordinator":
                roles = ["user", "teacher", "coordinator"];
                break;
            case "exam-manager":
                roles = ["user", "teacher", "coordinator", "exam-manager"];
                break;
            case "principle":
                roles = ["user", "teacher", "principle"];
                break;
            case "admin":
                roles = ["user", "principle", 'coordinator', 'admin'];
                break;
            case "cso":
                roles = ["user", "cso"];
                break;
            case "finance":
                roles = ["user", "finance"];
                break;
            case "marketing":
                roles = ["user", "marketing"];
                break;
            case "bod":
                roles = ["user", "teacher", "principle", 'coordinator', 'admin', 'bod', 'exam-manager'];
                break;
            case "only_teacher":
                roles = ["teacher"];
                break;
            default:
                roles = ["user"];
                break;
        }
        return roles;
    }

}
