import AssignmentService from "@/core/services/assignement/AssignmentService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AssignmentQuestionService from "@/core/services/assignement/AssignmentQuestionService";

const assignmentQuestionService = new AssignmentQuestionService();
const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();
const programService = new ProgramService();
const assignmentService = new AssignmentService();


export const AssignmentMixin = {
    data() {
        return {
            search: {
                program_id: null,
                grade_id: null,
            },
            programs: [],
            courses: [],
            gradesLevels: [],
            assignments: [],
            assignment_questions: [],
            isBusy: false,
            due_date_expired: false,
            assignments_stats: null,
            return_back: false,
            page: null,
            total: null,
            perPage: null,
            assignment: {
                title: null,
                description: null,
                feedback_types: [],
                program_id: null,
                grade_id: null,
                course_id: null,
                added_by: null,
                type: null,
                added_date: null,
                due_date: null,
                is_active: true
            }
        }
    },
    computed: {
        assignment_id() {
            return this.$route.params.assignment_id;
        },

        checkCutOffDate() {
            var today = this.$moment(new Date()).format("D, M, YY");
            var cut_off_date = this.$moment(this.assignment.cut_off_date).format("D, M, YY");
            if (cut_off_date >= today) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        createAndUpdate() {
            if (this.assignment.accepted_files)
                this.assignment.accepted_files = this.assignment.accepted_files.join(',')
            if (this.assignment.feedback_types)
                this.assignment.feedback_types = this.assignment.feedback_types.join(',')
            this.$v.$touch();
            if (this.$v.assignment.$error) {
                setTimeout(() => {
                    this.$v.$reset();
                }, 3000);
            } else {
                if (this.assignment.id) {
                    this.__update();
                } else {
                    this.__create();
                }
            }
        },
        createAndUpdateReturn() {
            this.return_back = true;
            this.createAndUpdate();
        },
        returnBack() {
            this.$router.push({
                name: 'courses-summary', params: {
                    lesson_planner_id: this.assignment.lesson_planner_id
                }
            })
        },
        // __create() {
        //     this.isBusy = true;
        //     assignmentService.store(this.assignment).then(response => {
        //         this.isBusy = false;
        //         if (this.return_back)
        //             this.returnBack();
        //         this.resetForm()
        //         this.$snotify.success("Created successfully");
        //     });
        // },
        // __update() {
        //     this.isBusy = true;
        //     assignmentService.update(this.assignment.id, this.assignment).then(response => {
        //         this.isBusy = false;
        //         if (this.return_back)
        //             this.returnBack();
        //         this.resetForm()
        //         this.$snotify.success("Updated successfully");
        //
        //     });
        // }, resetForm() {
        //     this.assignment = {
        //         level_id: null,
        //         program_id: null,
        //         title: null,
        //         start_date: null,
        //         end_date: null,
        //         is_active: true
        //     }
        //     this.edit = false
        //     this.$v.$reset()
        //     this.getSingleAssignment(this.assignment_id)
        //     this.$emit("refresh");
        // },
        getAssignments(searchData) {
            assignmentService.getStudentAssignment(searchData).then(response => {
                this.assignments = response.data.assignments;
            }).catch((err) => {
            }).finally(() => {
                this.loading = false

            });
        },
        getStudentAssignmentStats() {
            assignmentService.getStudentAssignmentStats().then(response => {
                this.assignments_stats = response.data.stats;
            }).catch((err) => {
            }).finally(() => {
                this.loading = false

            });
        },
        getAssignmentsQuestions() {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentQuestionService.paginate(this.search, this.page).then(response => {
                // console.log(response)
                this.assignment_questions = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            }).catch((err) => {
                // console.log(err)
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },

        getSingleAssignment(assignment_id) {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentService.show(assignment_id).then(response => {
                this.assignment = response.data.assignment;
                this.checkDueDate();

                if (this.assignment.accepted_files) {
                    this.assignment.accepted_files = this.assignment.accepted_files.split(',');
                }
                if (this.assignment.feedback_types) {
                    this.assignment.feedback_types = this.assignment.feedback_types.split(',');
                } else this.assignment.feedback_types = [];
            }).catch((err) => {
                // console.log(err)
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },
        getPrograms() {
            programService.all().then(response => {
                this.programs = response.data;
            });
        },

        getGrades() {
            gradeLevelService.getByProgram(this.search.program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },

        getCourses() {
            courseService.paginate(this.search).then(response => {
                this.courses = response.data.data;
            });
        },
        formatType($type) {
            if ($type.includes("_"))
                return $type.split('_').join(" ");
            else
                return $type;
        }
    },
}
