import Apiservice from '../../../api.service';
import {API_URL} from '../../../../../common/config.js'

const apiService = Apiservice;


export default class StudentGradeService{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;
    }
    getStudentCustomizeGrade(userId){
        let url = `${this.#api}/customize/${userId}/student-grade`;
        return apiService.get(url);
    }
}