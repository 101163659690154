import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class AttendanceService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/attendance`;

      }
      paginate() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

      update(attendanceId, data) {
            let url = `${this.#api}/${attendanceId}`
            return apiService.put(url, data);

      }

      store( data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }
      checkAvailablity( data) {
            let url = `${this.#api}/checkAvailablity`
            return apiService.post(url, data);

      }

      show( attendanceId) {
            let url = `${this.#api}/${attendanceId}`
            return apiService.get(url);
      }
      getTeacherTotalClassTaken( teacherId) {
            let url = `${this.#api}/teacher-count-class/${teacherId}`
            return apiService.get(url);
      }

      delete( attendanceId) {
            let url = `${this.#api}/${attendanceId}`
            return apiService.delete(url);
      }
      getReport(data,index = null) {
            let url = `${this.#api}-report`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index)
                  url = url + '?page=' + index;
            return apiService.get(url);
      }
      getStudentAttendance(data){
            let url = `${API_URL}user/student/attendance`
            if (data != null && data != undefined && data != "") {
                  var queryString = null;
                  var queryString = Object.keys(data).map((key) => {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  // console.log(queryString)
                  url = url + '?' + queryString;
            }
            return apiService.get(url);

      }
      getStudentAttendanceByTeacherId(data){
            let url = `${API_URL}user/student/attendance-teacher`
            if (data != null && data != undefined && data != "") {
                  var queryString = null;
                  var queryString = Object.keys(data).map((key) => {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  // console.log(queryString)
                  url = url + '?' + queryString;
            }
            return apiService.get(url);

      }
      getAttendanceReportByCourse(data = {}) {
            let url = `${API_URL}user/student/attendee/get/report-by-course`;
            let param ={
                  params: data
            }
            return apiService.query(url, param);
      }
}
