<template>
  <v-app>
    <div>
      <div class="row">
        <div class="card col-md-12">
          <div class="card-body">
            <div class="d-flex ">
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div v-if="currentUser.photo " class="symbol symbol-50 symbol-lg-120"><img
                    :src="currentUser.image_path!=null ? currentUser.image_path['thumb'] : ''"
                    alt="image"/></div>
                <div v-else class="symbol-label"><span class="symbol symbol-light-success"><span
                    class="symbol-label font-size-h5 font-weight-bold cursor-pointer"> {{
                    currentUser.first_name != null ? currentUser.first_name.charAt(0) : ''
                  }} </span></span>
                </div>
                <!--                  <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none"><span class="font-size-h3 symbol-label font-weight-boldest">JM</span></div>-->
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a href="#"
                       class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">{{
                        `${currentUser.first_name
                        != null ? currentUser.first_name : ''}
                                            ${currentUser.last_name != null ? currentUser.last_name : ''}`
                      }}</a><a
                      href="#"><i
                      class="flaticon2-correct text-success font-size-h6"></i></a>
                  </div>
                  <!--                    <div class="my-lg-0 my-3"><a href="#" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">ask</a><a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase">hire</a></div>-->
                </div>
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a href="#"
                         class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i
                          class="flaticon2-new-email mr-2 font-size-lg"></i>{{
                          currentUser.email
                          ||
                          'N/A'
                        }}</a>

                      <a href="#"
                         class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i
                          class="flaticon2-calendar-3 mr-2 font-size-lg"></i> CRN : {{
                          user ?
                              user.personal_id || 'N/A' : 'N/A'
                        }} </a>

                    </div>
                    <p><span class="font-weight-bold text-dark-50"><i
                        class="fas fa-university mr-2 font-size-lg"></i> {{
                        studentSetting != null ? studentSetting.program_title || 'N/A' : 'N/A'
                      }}</span>
                    </p>


                  </div>
                  <!--                    <div class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">-->
                  <!--                      <span class="font-weight-bold text-dark-75">Progress</span>-->
                  <!--                      <div class="progress progress-xs mx-3 w-100"><div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-success" style="width: 63%;"></div></div>-->
                  <!--                      <span class="font-weight-bolder text-dark">78%</span>-->
                  <!--                    </div>-->
                </div>
              </div>
            </div>


            <div class="separator separator-solid"></div>
            <div class="d-flex align-items-center icon-grid-info flex-wrap mt-8">
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2">
                                <span class="mr-4"><i
                                    class="flaticon-calendar-1 display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Batch</span><span
                    class="font-weight-bolder font-size-h5"><span
                    class="text-dark-50 font-weight-bold"></span> {{
                    studentSetting != null && studentSetting.academic_year ? studentSetting.academic_year : 'N/A'
                  }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2">
                                <span class="mr-4"><i
                                    class="flaticon-confetti display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Semester/year</span><span
                    class="font-weight-bolder font-size-h5"><span
                    class="text-dark-50 font-weight-bold"></span>{{
                    studentSetting != null && studentSetting.current_semester_title ?
                        studentSetting.current_semester_title :
                        'N/A'
                  }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2">
                <span class="mr-4"><i class="flaticon-laptop display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column text-dark-75" v-if="studentSetting!=null">
                  <span class="font-weight-bolder font-size-sm">Enrolled class</span><span
                    class="font-weight-bolder font-size-h5"><span
                    class="text-dark-50 font-weight-bold"></span>{{
                    studentSetting.current_class_title ? 'Section ' + studentSetting.current_class_title :
                        'N/A'
                  }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2" v-if="organisational_setting.assignment=='1'">
                <span class="mr-4"><i class="flaticon-file-2 display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column flex-lg-fill"><span
                    class="text-dark-75 font-weight-bolder font-size-sm">

                                    {{
                    assignments_stats ? assignments_stats.submitted_assignment : 0
                  }} / {{ assignments_stats ? assignments_stats.total_assignment : 0 }}
                                    Assignments</span>

                  <router-link
                      :to="{name:'assignment'}"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                  >

                    <a :href="href" class="text-primary font-weight-bolder">
                      <span class="menu-text">View</span>
                    </a>
                  </router-link>

                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2"  v-if="organisational_setting.attendance=='1'">
                                              <span class="mr-4"><i
                                                  class="flaticon2-pie-chart-4 display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column"><span
                    class="text-dark-75 font-weight-bolder font-size-sm">
                                      <span>
                                          <progress
                                              :title="statistics.total_attendance_percentage"
                                              :value="statistics.total_attendance_percentage?statistics.total_attendance_percentage:0"
                                              max="100">
                                       </progress>
                                        <br>
                                        {{ `${statistics.total_attendance_percentage?statistics.total_attendance_percentage:0}%` }}
                                        Attendance
                                      </span>


                                    </span>
<!--                  <a href="#" class="text-primary font-weight-bolder">View</a>-->
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-md-5 mb-2"  v-if="organisational_setting.finance=='1'">
                                              <span class="mr-4"><i
                                                  class="flaticon2-pie-chart-4 display-3 text-muted font-weight-bold"></i></span>
                <div class="d-flex flex-column"><span
                    class="text-dark-75 font-weight-bolder font-size-sm">
                                      <span>
                                        {{ `Rs.${statistics.total_pending?statistics.total_pending:0}` }}
                                        Pending due
                                      </span>


                                    </span>
<!--                  <a href="#" class="text-primary font-weight-bolder">View</a>-->
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-xl-12">
          <student :organisational_setting="organisational_setting"></student>
        </div>
        <div class="col-xl-12">
          <exam-performance></exam-performance>
        </div>
        <div class="col-xl-4">
          <h4>Semester/year info</h4>
          <v-treeview
              activatable
              color="info"
              :items="items"
          ></v-treeview>
        </div>
        <div class="col-xl-8">
          <attendance-chart :user="user"></attendance-chart>
        </div>

      </div>
      <!--end::Dashboard-->
    </div>
  </v-app>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Student from "./dashboard/Student";
import ConfigService from "@/core/services/config/ConfigService";
import UserService from "@/core/services/user/UserService";
import {AssignmentMixin} from "@/mixins/AssignmentMixin";
import DashboardService from "@/core/services/dashboard/DashboardService";
import ExamPerformance from "./dashboard/ExamPerformance.vue";
import StudentGradeService from "@/core/services/user/student/StudentGrade/StudentGradeService";
import AttendanceChart from "./dashboard/AttendanceChart.vue";
const studentGrade=new StudentGradeService;
const configService = new ConfigService();
const userService = new UserService();
const dashboardService = new DashboardService();


export default {
  name: "Dashboard",
  mixins: [AssignmentMixin],
  components: {
    Student,ExamPerformance,AttendanceChart
  },
  data() {
    return {
      slider: true,
      stats: {},
      statistics: {},
      search: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        type: 'student',
        limit: 25,

      },
      searchData: {
        start_from: null,
        end_to: null,
      },
      user: null,
      studentSetting: null,
      items:[],

    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    organisational_setting(){
      return this.$store.getters.getOrganisationSetting;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
    this.getUser();
    this.getStudentAssignmentStats()
    this.getStats();


    // this.getTimelineStats();

  },
  methods: {
    getStudentCustomizeGrade(){


        studentGrade
            .getStudentCustomizeGrade(this.user.id)
            .then(response => {
              this.items=response.data.list_student_grades;
            })
            .catch(error => {

            })
            .finally(() => {
              this.loading=false;
            })


    },
    getStats() {
      dashboardService.getStats().then(response => {
        this.statistics = response.data
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      })
    },

    getUser() {
      userService.show(this.currentUser.id).then(response => {
        this.user = response.data.user;
        this.getStudentCustomizeGrade();
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        this.studentSetting = this.user.setting ? this.user.setting : {}
      });
    },

    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      roles = roles.split(",");
      for (let t of roles) {
        t = t.trim();
        if (userRoles.includes(t)) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      }
      if (flag.includes(true)) {
        return true;
      } else return false;
    },


  }
};
</script>

<style scoped>
.theme--light.v-application {
  background: none !important;
  /*color: rgba(0, 0, 0, 0.87);*/
}
</style>

