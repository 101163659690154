import ApiService from "@/core/services/api.service";
import { API_URL } from '@/common/config.js'
const apiService = ApiService;


export default class BookService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/student/book`;

      }

      paginate(data = null, index=null) {
            let url = `${this.#api}`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index != null && !queryString)
                  url = url + '?' + "page=" + (index);
            else
                  url = url + '&' + "page=" + (index);
            return apiService.get(url);
      }

      bookByProgramAndSemester(){
            let url = `${this.#api}/by/program-and-semester`
            return apiService.get(url);
      }


      getActive(){
            let url = `${this.#api}/active/all`
            return apiService.get(url);

      }

      update(id, data) {
            let url = `${this.#api}/${id}/update`
            return apiService.post(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }
      sort( data) {
            let url = `${this.#api}/sort`;
            return apiService.post(url, data);
      }


      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      resourcesByKey(key) {
            let url = `${this.#api}/search/${key}`
            return apiService.get(url);
      }
      getStudentLibraryStats(data={}){
            let url = `${API_URL}user/student/library/books/student-stats`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }
      exportStudentLibraryBookHistory(data={})
      {
            let url = `${API_URL}user/student/library/export/student-history`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            window.open(url);
      }
}
