<template>
  <div>
    <v-responsive>
      <div class="border-graph">
        <v-row>
          <v-col cols="8">
            <h4>Library stats</h4>
          </v-col>
          <v-col cols="4" class="text-right">
            <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
              <template v-slot:button-content>
                <i class="ki ki-bold-more-hor"></i>
              </template>
              <!--begin::Navigation-->
              <div class="navi navi-hover min-w-md-250px" >
                <b-dropdown-text tag="div" class="navi-item"  >
                  <a  @click="exportStudentBookHistory" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                    <span class="navi-text">Download book history</span>
                  </a>
                </b-dropdown-text>

              </div>
              <!--end::Navigation-->
            </b-dropdown>
          </v-col>
          <v-col md="12" >
            <span class="badge badge-primary ">  <b>Books issued: {{ total_book_issued_count }}</b></span>
            <span class="badge badge-success ">  <b>Books returned: {{total_book_returned_count}}</b></span>
            <span class="badge badge-danger">  <b>Books due: {{ total_due_count }}</b></span>

          </v-col>

          <v-divider></v-divider>
          <v-col md="12">
            <v-timeline
                align-top
                dense
            >

              <v-virtual-scroll
                  :bench="benched"
                  :items="items"
                  height="360"
                  item-height="60"
              >
                <template v-slot:default="{ item }">

                  <v-timeline-item
                      :color="item.color"
                      small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>{{item.date}}</strong>
                      </v-col>
                      <v-col>
                        <strong>{{item.title}}</strong>
                        <div class="text-caption">
                          {{item.type}}
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </template>
              </v-virtual-scroll>
            </v-timeline>

          </v-col>
        </v-row>

      </div>
    </v-responsive>

  </div>
</template>
<script>
import BookService from "@/core/services/library/book/BookService";
const book=new BookService;
export default {
  props:['user'],
  data(){
    return{
      loading:false,
      benched: 0,
      items:[],
      total_book_issued_count:0,
      total_book_returned_count:0,
      total_due_count:0,
    }
  },
  methods:{
    getStudentLibraryStats(){
      this.loading=true;
      book
          .getStudentLibraryStats()
          .then(response => {
            this.items=response.data.student_issued_books;
            this.total_book_issued_count=response.data.total_book_issued_count;
            this.total_book_returned_count=response.data.total_book_returned_count;
            this.total_due_count=response.data.total_due_count;


          })
          .catch((err) => {

          })
          .finally(()=>{
            this.loading=false;
          });
    },
    exportStudentBookHistory(){
      book
          .exportStudentLibraryBookHistory(null)
    },
  },
  mounted() {
    this.getStudentLibraryStats();
  }
}
</script>