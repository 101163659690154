import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class GradeLevelService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/grades`;

      }
      all() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }



      getByProgram(programId) {
            let url = `${API_URL}user/program/${programId}/grades`;
            return apiService.get(url);
      }


      paginate(type = null) {
            let url = `${this.#api}`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
