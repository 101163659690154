<template>
  <v-app>
    <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="border-graph">
            <v-row>
              <v-col md="12">
                <h4>Exam performance of semester/year</h4>
              </v-col>
              <v-col md="3" class="ml-3">
                <span class="font-weight-bold">Show table view</span>
                <v-switch @change="changeExamPerformanceTableView"
                          v-model="is_table_view"
                          :label="is_table_view ? 'Yes': 'No'"
                          color="primary"

                          hide-details>
                </v-switch>
              </v-col>

              <v-col md="3" class="ml-3">
                <v-select  @change="getExamByStudentGradeId" v-model="search.student_grade_id" item-value="id" item-text="grade_title" dense outlined :items="grades" ></v-select>
              </v-col>
              <v-col md="4">
                <v-autocomplete @change="changeExamData" v-model="search.exam_id" item-value="id" item-text="title" dense outlined :items="exams" ></v-autocomplete>
              </v-col>
              <v-col md="12" class="mt-2" v-if="!loading && !is_table_view">
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
              </v-col>
              <v-col md="12" class="mt-2" v-if="!loading && is_table_view">
                <table class="table">
                  <thead>
                  <tr class="px-3">
                    <td>Subject</td>
                    <td>Total marks</td>
                    <td>Pass marks</td>
                    <td>Total obtained marks</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="px-3" v-show="exam_performance_table_lists.length > 0" v-for="(exam_performance_table_list,index ) in exam_performance_table_lists" :key="index">
                    <td>
                  <span class="font-weight-bold">
                    {{exam_performance_table_list.course_title}}
                  </span>
                    </td>
                    <td>
                  <span class="font-weight-medium">
                    {{exam_performance_table_list.total_marks}}
                  </span>
                    </td>
                    <td>
                  <span class="font-weight-medium">
                    {{exam_performance_table_list.pass_marks}}
                  </span>
                    </td>

                    <td>
                  <span class="font-weight-medium text-success" v-if="parseInt(exam_performance_table_list.total_obtained_marks) > parseInt(exam_performance_table_list.pass_marks)">
                    {{exam_performance_table_list.total_obtained_marks}}
                  </span>
                      <span class="font-weight-medium text-danger" v-else>
                    {{exam_performance_table_list.total_obtained_marks}}
                  </span>
                    </td>
                  </tr>
                  <tr v-show="exam_performance_table_lists.length == 0">
                    No items available.
                  </tr>
                  </tbody>

                </table>
              </v-col>
            </v-row>

          </div>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>
<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import ExamService from "@/core/services/exam/ExamService";
const exam=new ExamService;
const studentSetting=new StudentSettingService;
const dashboard=new DashboardService;
export default {
  data(){
    return{
      exam_performance_table_lists:[],
      is_table_view:false,
      exams:[],
      grades:[],
      search:{
        student_grade_id:null,
        exam_id:null,
      },
      exam_performance_lists:[],
      loading:false,
      grade:'',
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: [],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'string'
        },
        yaxis: {
          title: {
            text: 'Points',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;

            }
          }
        }
      },

    }
  },
  methods:{
    getGrades() {
      this.search.student_grade_id=null;
      studentSetting
          .getAllStudentGrades()
          .then(response => {
            this.grades = response.data.data;
            if(this.grades.length > 0){
              let currentGrade =  this.grades.filter(function (grade) {
                return grade.status=='current';
              });
              if(currentGrade.length > 0){
                this.search.student_grade_id=currentGrade[0].id;
              }else{
                this.search.student_grade_id=this.grades[0].id;
              }
              this.getExamByStudentGradeId()

            }
          }).catch((error) => {

      })
          .finally(() => {

          });
    },
    getExamByStudentGradeId(){
      if(this.search.student_grade_id){

        exam
            .getExamByStudentGradeId(this.search.student_grade_id)
            .then(response => {
              this.exams=response.data.exams;
              if(this.exams.length > 0){
                this.search.exam_id=this.exams[0].id;
              }
              if(this.search.exam_id){
                if(this.is_table_view){
                  this.getStudentExamPerformanceInTableView();
                }else{
                  this.getStudentExamPerformance();
                }

              }

            })
            .catch(error => {

            })
            .finally(() => {

            });
      }
    },

    getStudentExamPerformance(){
      this.loading=true;
      dashboard
          .getExamPerformanceStats(this.search)
          .then(response => {
            let examPerformanceLists=response.data.exam_performance_lists;

            let series=[];
            let courses_title=[];
            examPerformanceLists.forEach(function (item) {
              series.push(item.total_obtained_mark);
              series.push(item.total_marks);
              series.push(item.pass_marks);
              courses_title=item.courses_title;
            });
            this.series=series;
            this.chartOptions.labels=courses_title;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          });
    },

    getStudentExamPerformanceInTableView(){
      this.exam_performance_table_lists=[];
      this.loading=true;
      dashboard
          .getExamPerformanceStatsInTableView(this.search)
          .then(response => {
            this.exam_performance_table_lists=response.data.exam_performance_table_lists;

          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          });
    },

    changeExamData(){
      if(this.is_table_view){
        this.getStudentExamPerformanceInTableView();
      }else{
        this.getStudentExamPerformance();
      }
    },
    changeExamPerformanceTableView(){
      if(this.is_table_view){
        this.getStudentExamPerformanceInTableView();
      }else{
        this.getStudentExamPerformance();
      }
    },
  },
  mounted() {
    this.getGrades();

  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>
