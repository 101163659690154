import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class ExamService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/`;

    }

    all(data={}) {
        let url = `${this.#api}exams`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }


    getResult(data=null) {
        let url = `${this.#api}exam/result`;
        return apiService.post(url,data);
    }
    getGrade(data=null) {
        let url = `${this.#api}histories`;
        return apiService.get(url);
    }




    paginate(data,index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index)
            url = url + '?page=' + index;
        return apiService.get(url);
    }

    syncCourses(id, data) {
        let url = `${this.#api}/${id}/sync-courses`
        return apiService.post(url, data);

    }

    saveTimetable(id, data) {
        let url = `${this.#api}/${id}/save-course-timetable`
        return apiService.post(url, data);

    }

    getAssignedCourses(examId) {
        let url = `${this.#api}/assigned/${examId}/courses`
        return apiService.get(url);
    }

    getAssignedPivotCourses(examId) {
        let url = `${this.#api}/pivot/assigned/${examId}/courses`
        return apiService.get(url);
    }


    getAssignedStudents(examId) {
        let url = `${this.#api}/${examId}/assigned/students`
        return apiService.get(url);
    }

    getAssignedStudentsByCourse(examId, courseId) {
        let url = `${this.#api}/${examId}/course/${courseId}/students`
        return apiService.get(url);
    }


    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }


    getExamByStudentGradeId(studentGradeId=null,data=null)
    {
        let url = `${this.#api}exams/get-by-student-grade/${studentGradeId}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}
