import CalenderService from "@/core/services/calender/CalenderService";

const calender = new CalenderService();
export const CalenderMixin = {
    data() {
        return {
            loading: false,
            caledner_details: null,
            assignments: [],
            upcoming_events: [],
            exams: [],
            attendance: [],
            attendacneReport: null,
            attendacneReportSummary: [],
            attendanceFilter: {
                year: new Date().getFullYear(),
                month: ((new Date().getMonth() + 1)).toString().padStart(2, '0'),
                course_id: null
            }
        }
    },
    methods: {
        getCalender() {
            this.loading = true;
            calender.show().then(response => {
                this.caledner_details = response.data.data;
            }).finally(() => {
                this.loading = false;
            })
        },
        getReports() {
            this.getAttendanceReport();
            this.getAttendanceSummary();
        },
        getAttendanceReport() {
            this.loading = true;
            calender.attendance(this.attendanceFilter).then(response => {
                this.attendacneReport = response.data.attendance;
            }).finally(() => {
                this.loading = false;
            })
        },
        getAttendanceSummary() {
            this.loading = true;
            calender.attendanceSummary(this.attendanceFilter).then(response => {
                this.attendacneReportSummary = response.data.attendance;
            }).finally(() => {
                this.loading = false;
            })
        },
        getAttendanceNoteName(title) {
            return this.attendacneReport.note[title];
        }
    },
    computed: {
        calender_items() {
            if (this.caledner_details && this.caledner_details) {
                let events = this.caledner_details.map
                (event => {
                    let data = null;
                    if (event.type === 'assignment') {
                        data = {
                            color: event.color,
                            content: event.desc,
                            start_date: this.$moment(event.start_date).format('YYYY-MM-DD hh:mm'),
                            title: event.title,
                            type: event.type,
                        }
                    } else {
                        data = {
                            color: event.color,
                            content: event.desc,
                            start_date: this.$moment(event.start_date).format('YYYY-MM-DD hh:mm'),
                            end_date: this.$moment(event.end_date).format('YYYY-MM-DD hh:mm'),
                            title: event.title,
                            type: event.type,
                        }
                    }

                    return data;
                });

                let calender_events = [...events];
                return calender_events;
            } else
                return []

        },

        calender_attendacne_items() {
            if (this.attendacneReport && this.attendacneReport.attendance_report) {
                let events = this.attendacneReport.attendance_report.map
                (event => {
                    let data = {
                        color: event.status === 'n' ? "white" : event.color,
                        start: this.$moment(event.date).format('YYYY-MM-DD hh:mm'),
                        name: event.status === 'n' ? " " : this.getAttendanceNoteName(event.status),
                    }

                    return data;
                });

                let calender_events = [...events];
                return calender_events;
            } else
                return []

        },
    }
}