import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'

const apiService = Apiservice;

export default class AcademicCalendarEventService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/academic-calendar-event`;
    }

    getByAcademicCalendar(academicCalendarId) {
        let url = `${this.#api}/academic-calendar/${academicCalendarId}/get`
        return apiService.get(url);
    }

    getByProgram(programId, data){
        let url = `${this.#api}/program/${programId}/get`;
        if (data != null && data != undefined && data != "") {
                var queryString = Object.keys(data).map((key) => {
                    if (data[key] && data[key] != null)
                            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
        }

        return apiService.get(url);
    }
}
