<template>
    <div>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                    Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
        </v-sheet>
        <v-sheet height="400">
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="items"
                @click:event="showEvent"
                :event-overlap-threshold="30"
            ></v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
            >
                <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                >
                    <v-toolbar
                        :color="selectedPopupEvent.color"
                        dark
                    >
                        <v-toolbar-title v-html="selectedPopupEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text v-if="selectedPopupEvent.type == 'event'">
                        <span>{{ selectedPopupEvent.start_time }} to {{ selectedPopupEvent.end_time }}</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            class="text-gray btn btn-standard"
                            @click="selectedOpen = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-sheet>
    </div>
</template>

<script>
    import BlogService from "@/core/services/blog/BlogService";
    import AcademicCalendarEventService from "@/core/services/academic-calendar-event/AcademicCalendarEventService";

    const blogService = new BlogService();
    const academicCalendarEvent = new AcademicCalendarEventService();

    import {CalenderMixin} from "@/mixins/CalenderMixin";


    export default {
        name: "EventComponents",
        mixins : [CalenderMixin],
        data() {
            return {
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                more: true,
                focus: '',
                type: 'month',
                mode: 'column',
                types: ['month', 'week', 'day', '4day'],
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                events:[

                ],
                value: '',
                weekday: [0, 1, 2, 3, 4, 5, 6],
                selectedPopupEvent: {},
            }
        },
        mounted() {
            this.getEvents();
            this.getCalender(this.currentUser.id);
        },
        methods: {
            viewDay ({ date }) {
                this.focus = date
                this.type = 'day'
            },
            getEvents() {
                let data = {
                    academic_year_id: this.currentUser.current_grade.academic_year_id
                };
                
                academicCalendarEvent
                .getByProgram(this.currentUser.current_grade.program_id, data)
                .then((res) => {
                    this.events = res.data.data;
                })
                .catch((err) => {})
                .finally(() => {});
            },

            prev() {
                this.$refs.calendar.prev();
            },
            next() {
                this.$refs.calendar.next();
            },

            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            setToday() {
                this.focus = ''
            },
            showEvent({nativeEvent, event}) {
                const open = () => {
                    this.selectedPopupEvent = event
                    this.selectedElement = nativeEvent.target
                    setTimeout(() => {
                        this.selectedOpen = true
                    }, 10)
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    setTimeout(open, 10)
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
        },
        computed: {
            items() {
                const items = [];
                this.events.forEach((item) => {
                    items.push({
                        id: item.academic_event_id,
                        name: item.academic_event_title,
                        start: item.academic_event_start_date,
                        end: item.academic_event_end_date,
                        start_time: item.start_time,
                        end_time: item.end_time,
                        type: item.type,
                        color: item.color,
                        timed: false,
                    })
                })
                return items;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
    }
</script>

<style scoped>

</style>